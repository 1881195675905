import React from "react"
import { Seo, Layout, Section, Container } from "../components"

function PrivacyPolicy() {
  return (
    <>
      <Layout>
        <Seo
          title="Our Privacy Policy"
          description="This Privacy Statement describes privacy practices of ShipX Inc.
                with respect to information we collect about customers."
          slug="privacy-policy"
        />
        <Section padding="lg">
          <Container padding="global-md" classes="bg-white">
            <div className="px-12 space-y-6">
              <center>
                <h1>SHIPX INC.’S PRIVACY STATEMENT</h1>
                <p className="underline">Effective Date: 8/20/2021</p>
              </center>
              <h2 className="text-secondary">PURPOSE</h2>
              <p>
                This Privacy Statement describes privacy practices of ShipX Inc.
                with respect to information we collect about customers and
                visitors who either purchase services or inquire about our
                services through our website.
              </p>
              <h2 className="text-secondary">COMMITMENT TO PRIVACY</h2>
              <p>
                ShipX Inc. knows that you care how information about you is used
                and shared. ShipX Inc. respects your right to privacy. Your
                ability to make informed choices about the use of your
                information is important to us. We want to make sure that you
                understand what information we collect about you, how we use it,
                and the safeguards we have in place in order to protect it. This
                Privacy Statement explains our policy regarding the collection,
                use, disclosure and protection of personal information.
              </p>
              <h2 className="text-secondary">
                WHAT INFORMATION DO WE COLLECT ?
              </h2>{" "}
              <p>
                The information we collect and retain about you depends upon
                your interaction with us.
              </p>
              <p>
                Information You Give Us. We collect personally identifiable
                information (such as your name, address, e-mail address and
                telephone number(s)) you provide us when you:
              </p>
              <ul className="list-disc pt-4 ml-5 text-gray-800 text-sm">
                <li className="pb-4">PURCHASE SERVICES FROM US.</li>

                <li className="pb-4">
                  COMMUNICATE WITH US FOR ANY REASON, SUCH AS INQUIRING ABOUT
                  THE SERVICES YOU ORDERED OR MAY WISH TO ORDER THROUGH OUR
                  WEBSITE; OR
                </li>

                <li>
                  VOLUNTARILY PARTICIPATE IN A POST-SERVICES SURVEY.
                  (INFORMATION WE COLLECT MAY INCLUDE DEMOGRAPHIC INFORMATION,
                  SUCH AS AGE AND GENDER AND NUMBER OF HOUSEHOLD MEMBERS).
                </li>
              </ul>
              <p>
                Automatic Information. When you interact with us, we may
                maintain a record of the following types of information:
              </p>
              <ul className="list-disc ml-5 text-gray-800 text-sm">
                <li className="mb-4">
                  YOUR PURCHASE OF THE PRODUCT FROM A THIRD PARTY AND OUR
                  ARRANGING OF DELIVERY OF THAT PRODUCT.
                </li>

                <li>
                  YOUR INTERNET PROTOCOL (“IP”) ADDRESS WHEN YOU VISIT OUR
                  WEBSITE. THE IP ADDRESS COLLECTED ON OUR WEBSITE IS NOT LINKED
                  BY US TO PERSONALLY IDENTIFIABLE INFORMATION, EXCEPT IN CASES
                  WHERE WE MUST PROVIDE IP ADDRESSES TO THE COMPANY FROM WHOM
                  YOU PURCHASED YOUR PRODUCT AND/OR CERTAIN CREDIT CARD
                  PROCESSORS IN CONNECTION WITH THE PROCESSING OF YOUR CREDIT
                  CARD TRANSACTION. YOU MAY WANT TO CONTACT YOUR CREDIT CARD
                  PROVIDER WITH ANY QUESTIONS.
                </li>
              </ul>{" "}
              <p>
                Information from Third Parties. We may receive personally
                identifiable information from third parties as follows:
              </p>{" "}
              <ul className="list-disc ml-5 text-gray-800 text-sm">
                <li>
                  INFORMATION FROM YOUR PRODUCT SUPPLIER FROM WHOM YOU PURCHASED
                  THE PRODUCT THAT WE WILL BE ARRANGING FOR DELIVERY FOR YOU.
                </li>{" "}
                <li>
                  INFORMATION FROM OUR DELIVERY PARTNERS (SEE – “DO WE SHARE
                  YOUR INFORMATION WITH THIRD PARTIES” BELOW FOR MORE
                  INFORMATION ABOUT OUR DELIVERY PARTNERS); AND
                </li>{" "}
                <li>
                  UPDATED DELIVERY AND ADDRESS INFORMATION FROM THE SHIPPER OF
                  YOUR PRODUCT OR OTHER THIRD PARTIES, SUCH AS DELIVERY PARTNERS
                  AND PRODUCT SUPPLIERS WHO FURNISH VARIOUS FULFILLMENT AND
                  CONSULTING SERVICES
                </li>
              </ul>{" "}
              <h2 className="text-secondary">DO WE USE COOKIES?</h2>
              <p>
                Cookies are small pieces of information sent by a website that
                are stored by your web browser on your computer’s hard drive.
                ShipX Inc. uses cookies to obtain certain types of information
                when your web browser accesses ShipX Inc.’s website when you log
                into ShipX Inc.’s website to make it easier for you to use the
                ShipX Inc. website. If you do not want to store this
                information, or if you are using a public computer, the Help
                portion of the toolbar on most browsers will tell you how to
                prevent your browser from accepting new cookies, how to have the
                browser notify you when you receive a new cookie, or how to
                disable cookies altogether. Your product supplier may also use
                cookies on its website.
              </p>{" "}
              <h2 className="text-secondary">
                HOW DO WE USE YOUR INFORMATION?
              </h2>{" "}
              <p>
                The personal information we collect is generally used to process
                your transaction and other related requests and comments, and to
                understand your needs so that we may arrange for you with the
                most suitable services. For example, we may use your postal
                address, e-mail address or telephone number to:
              </p>{" "}
              <ul className="list-disc ml-5 text-gray-800 text-sm">
                <li>
                  PROCESS AND FULFILL YOUR ORDER AND NOTIFY YOU OF YOUR ORDER
                  STATUS;
                </li>{" "}
                <li>PROVIDE CUSTOMER SUPPORT;</li>
                <li>
                  SOLICIT YOUR FEEDBACK ABOUT THE SERVICE YOU RECEIVED.
                </li>{" "}
              </ul>
              <p>
                The non-personally identifiable information that we
                automatically collect may be utilized in the aggregate to help
                us look for trends so that we can improve your shopping
                experience.
              </p>{" "}
              <p>
                We strive to comply with prevailing industry standards regarding
                the use of your personal information.
              </p>{" "}
              <h2 className="text-secondary">
                DO WE SHARE YOUR INFORMATION WITH THIRD PARTIES?
              </h2>{" "}
              <p>
                We do not share your information with unaffiliated third
                parties, except as described below. In these circumstances, we
                limit the information we provide to the information necessary to
                perform the function or provide the service for which it is
                shared. These third parties are not permitted to use the
                personal information, which is provided by us for any purposes
                other than as authorized by us, and these third parties are
                required to keep the information confidential.
              </p>
              <ul className="list-disc ml-5 text-sm text-gray-800">
                <li>
                  DELIVERY PARTNERS: WE MAY SHARE YOUR PERSONALLY IDENTIFIABLE
                  INFORMATION WITH OUR TRUSTED DELIVERY PARTNERS THAT WILL BE
                  PROVIDING THE DELIVERY SERVICES TO YOU OR WHO PERFORM
                  FOLLOW-UP AND POST-DELIVERY SERVICES.
                </li>{" "}
                <li>
                  PRODUCT SUPPLIERS: WE WILL SHARE YOUR PERSONALLY IDENTIFIABLE
                  INFORMATION WITH THE SUPPLIER OF THE PRODUCT THAT YOU ARE
                  HAVING DELIVERED. THIS INFORMATION IS SUBJECT TO THE PRIVACY
                  STATEMENT OF YOUR PRODUCT SUPPLIER.
                </li>{" "}
                <li>
                  OTHER DISCLOSURES: A) NECESSARY TO COMPLY WITH LEGAL PROCESS,
                  B) IN RESPONSE TO LEGAL CLAIMS, C) NECESSARY TO PROTECT
                  PERSONAL SAFETY OR D) AN INTEGRAL PART OF A SALE OF ALL OR
                  PART OF OUR BUSINESS.
                </li>
              </ul>
              <p>
                We may share aggregated demographic information and/or customer
                profile data with our business partners, advertisers and others.
                This is not linked to any personal information that can identify
                any individual person.
              </p>
              <h2 className="text-secondary">
                WHAT CHOICES DO I HAVE REGARDING THE USE OF MY INFORMATION?
              </h2>
              <p>
                We want to communicate with you only with respect to your
                services order or inquiries about our services. We will give you
                the opportunity to opt-out of participating in our post-service
                e-mail survey.
              </p>
              <h2 className="text-secondary">IS MY INFORMATION SECURE?</h2>{" "}
              <p>
                ShipX Inc. is committed to maintaining the security of
                information under our control. To try to prevent unauthorized
                access, maintain data accuracy, and ensure correct use of
                information, we have put in place the appropriate physical,
                electronic and managerial procedures to safeguard and secure the
                information we collect. Any information collected on ShipX
                Inc.’s website is located on a secured server behind a firewall
                and is not directly connected to the internet. ShipX Inc.
                employs Secure Socket Layer (“SSL”) software and password
                protection procedures to safeguard personal information
                collected.
              </p>
              <h2 className="text-secondary">
                HOW AM I NOTIFIED OF CHANGES TO THIS PRIVACY STATEMENT?
              </h2>{" "}
              <p>
                We reserve the right to change this Privacy Statement at any
                time but will alert you that the changes have been made, by
                indicating at the top of the Privacy Statement the date it was
                last updated. If there is ever a material change to how we use
                information you provide, and the new uses are unrelated to uses
                we disclose in this statement, we will communicate the changes
                in advance and provide you with the opportunity to change your
                marketing preferences. We will use information in accordance
                with the privacy policy under which the information was
                collected.
              </p>{" "}
              <h2 className="text-secondary">CHILDREN</h2>
              <p>
                We encourage parents to take an active interest in their
                children’s use of the Internet. ShipX Inc.’s website is not
                targeted to children younger than 13. ShipX Inc. does not
                knowingly collect or store information of children younger than
                13. Visitors to ShipX Inc.’s website who are under the age of 13
                may use this website only with the supervision and consent of a
                parent or guardian. None of the products or services available
                through ShipX Inc.’s website may be purchased by anyone under 18
                years of age.
              </p>
            </div>
          </Container>
        </Section>
      </Layout>
    </>
  )
}

export default PrivacyPolicy
